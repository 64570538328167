@import './shared/styles/_vuetify-overrides.scss';



























































































































.cassie-switch-enabled {
	color: var(--global-cassie-switch-enabled-color);
	margin-top: 3px;
}

.cassie-switch-disabled {
	color: var(--global-cassie-switch-disabled-color);
	margin-top: 3px;
}

.cassie-hide-validation {
	.v-messages.v-messages {
		display: none !important;
	}

	.v-input__prepend-outer {
		flex: 1 0 70%;
	}
}

.cassie-aligned-toggles {
	width: 100%;
	justify-content: space-between;
}

